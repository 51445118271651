import React, { useState, useEffect } from 'react';
import './App.css';
import { Carousel, Navbar, Nav, Container, Modal} from 'react-bootstrap';
import { darkTheme, SwapWidget } from '@uniswap/widgets';
import '@uniswap/widgets/fonts.css';
import Draggable from 'react-draggable';
import 'font-awesome/css/font-awesome.min.css';
import { Link, animateScroll as scroll } from 'react-scroll';


const myDarkTheme = {
  ...darkTheme, // Extend the darkTheme
  accent: '#2172E5',
  primary: '#FFFFFF',
  secondary: '#888D9B',
  fontFamily: '"Helvetica"',
}

const defaultOutputScom = '0xf8212179CE95b8237Ae8BDc7280288Bb349F78e5'


function Footer() {
  return (
      <footer className="footer">
          <div className="container">
              <div className="footer-content">
              <div className="social-links">
                      <a href="https://t.me/scomcoin" target="_blank" rel="noopener noreferrer">
                          <i className="fa fa-telegram"></i>
                      </a>
                      <a href="https://twitter.com/scomcoin" target="_blank" rel="noopener noreferrer">
                          <i className="fa fa-twitter"></i>
                      </a>
                      {/* Add other social links as needed */}
                  </div>
              </div>
          </div>
      </footer>
  );
}

function App() {

  // |------------------ Sticky Banner Stuff ------------------|
  const [currentIndex, setCurrentIndex] = useState(0);
  const messages = ["START WITH AS LITTLE AS $1!!!", "MARKET GOT YOU DOWN??? BUY SCOM!!!", "DONT MISS OUT ON THIS AMAZING OPPORTUNITY!!!"];

  useEffect(() => {
    const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 20000); // Change messages every 20 seconds to match the animation duration

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  // |------------------ End Sticky Banner Stuff ------------------|


  // |------------------ Uniswap Widget Modal Popup stuff ------------------|
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  // |------------------ End Uniswap Widget Modal Popup stuff ------------------|

  // |------------------ New Modals Stuff ------------------|

  const [numOfModalsToShow, setNumOfModalsToShow] = useState(0);
  const popupMessages = [
    "I DO NOT ENDORSE SHITCOINS.",
    "I have never worked with someone more unprofessional or deranged and due to this I left the team before payment.",
    "I didn't sell a single token at any point since deployment. Just added/removed 2 sided liquidity and bought",
    "I hate it here in prison, they dont even give me my adderal",
    "Fuck your life, buy SCOM",
    "The fundamental value of this token is $0",
    "This is a memecoin. There is no roadmap",
    "100% of net profits, past and future, made by the deployer from BALD will be going to nonprofits.",
    "iNVESTiGATiNG A POTENTiAL EXPLOiT",
    "To the hacker:  please send 90% of the funds back to our ARB MULTISIG ",
    "We have a bug in our contract we cannot hotfix",
    "Sorry... our Dev died during mint",
    "Where the fuck is the 60 million dollars???",
    "Join us in generational wealth",
  ];

  // Refs for the sections of the "important-section"
  const sectionRefs = Array(5).fill().map(() => React.createRef());

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          console.log("Observer triggered for:", entry.target); 
          if (entry.isIntersecting) {
            const sectionIndex = parseInt(entry.target.dataset.index, 10);
            console.log("Setting modals for section:", sectionIndex); 
            setNumOfModalsToShow((sectionIndex + 1) * 2);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );
  
    sectionRefs.forEach((ref, index) => {
      if (ref.current) {
        ref.current.dataset.index = index; // Set the index in the dataset
        observer.observe(ref.current);
      }
    });
  
    return () => {
      sectionRefs.forEach(ref => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);
  
  
  // Function to generate a random position for the modal
  const getRandomPosition = () => {
    const modalWidth = 400;  // Estimate or you can set to your modal's width
    const modalHeight = 200; // Estimate or you can set to your modal's height

    const maxTop = window.innerHeight - modalHeight;
    const maxLeft = window.innerWidth - modalWidth;

    const top = Math.random() * maxTop;
    const left = Math.random() * maxLeft;

    return { top, left };
  };
  // |------------------ End New Modals Stuff ------------------|


  return (
    <div className="App">
      {/* Navbar */}

      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Text>
            1.800.SCOM.LIFE | 0xf8212179CE95b8237Ae8BDc7280288Bb349F78e5
          </Navbar.Text>
          <Navbar.Toggle aria-controls="navbarNav" />
          <Navbar.Collapse id="navbarNav">
            <Nav className="ml-auto">
              <Nav.Link href="#">ACCESS MY PROGRAMS</Nav.Link>
              <Nav.Link href="#">HELP</Nav.Link>
              <Nav.Link href="#">LOGIN</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Second Navbar */}
      <nav className="navbar navbar-expand-lg custom-bg">
          <div className="container">
              <div className="container logo-container">
                  {/* Logo */}
                  <a className="navbar-brand logo-adjust" href="https://www.tailopez.com/">
                      <img src={process.env.PUBLIC_URL + "images/scomlogo5.png"} alt="Logo" width="60" height="60" />
                      <img src={process.env.PUBLIC_URL + "images/Scome_business.png"} alt="Logo" width="199" height="44" />
                  </a>
              </div>
              {/* Links */}
              <div className="navbar-collapse" id="navbarNav2">
                  <ul className="navbar-nav ml-auto">
                      <li className="nav-item">
                          <a className="nav-link" href="#">BECOME CRYPTO MILLIONAIRE</a>
                      </li>
                      <li className="nav-item separator">
                          <a className="nav-link" href="#">|</a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" href="https://twitter.com/scomcoin">TWITTER</a>
                      </li>
                      <li className="nav-item separator">
                          <a className="nav-link" href="#">|</a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" href="https://t.me/scomcoin">TELEGRAM</a>
                      </li>
                      <li className="nav-item separator">
                          <a className="nav-link" href="#">|</a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" href="https://miladymaker.net/miladyminecraft.pdf">WHITEPAPER</a>
                      </li>
                      <li className="nav-item separator">
                          <a className="nav-link" href="#">|</a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" href="#" onClick={handleShow}>BUY SCOM</a>
                      </li>
                      <li className="nav-item separator">
                          <a className="nav-link" href="#">|</a>
                      </li>
                      <li className="nav-item">
                        <Link 
                            className="nav-link" 
                            to="join-us-section" 
                            spy={true} 
                            smooth={true} 
                            offset={-70} 
                            duration={500}
                        >
                            CHART
                        </Link>
                      </li>
                  </ul>
              </div>
          </div>
      </nav>

      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={process.env.PUBLIC_URL + "images/HOW_TO_BECOME.png"}
            alt="Slide 1"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={process.env.PUBLIC_URL + "images/WILL_BLOW_UR_MIND.png"}
            alt="Slide 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={process.env.PUBLIC_URL + "images/$SCOM.png"}
            alt="Slide 3"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={process.env.PUBLIC_URL + "images/carousel9.png"}
            alt="Slide 4"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={process.env.PUBLIC_URL + "images/carousel5.png"}
            alt="Slide 5"
          />
        </Carousel.Item>
      </Carousel>

      {/* New Section */}
      <section className="image-section">
          <div className="overlay"></div> 
          <div className="content">
              <h2 className="text-center mb-4 scom-title"><span className="top-free">SCOM'S BESTSELLING</span><br /><span className="trainings">COURSES & TRAININGS</span></h2>
              <a href="https://www.tailopez.com/flow.php?id=FS-8155&source=carousel&utm_campaign=91" target="_blank" rel="noopener noreferrer">
                  <img src={process.env.PUBLIC_URL + "images/course1.png"} alt="Description 1" />
              </a>
              <a href="https://www.tailopez.com/flow.php?id=FS-3256&source=carousel&utm_campaign=92" target="_blank" rel="noopener noreferrer">
                  <img src={process.env.PUBLIC_URL + "images/course2.png"} alt="Description 2" />
              </a>
              <a href="https://www.tailopez.com/product.php?id=FS-1013&_s=64fff394d67eb" target="_blank" rel="noopener noreferrer">
                  <img src={process.env.PUBLIC_URL + "images/course3.png"} alt="Description 3" />
              </a>
              <a href="https://www.tailopez.com/flow.php?id=FS-8947&source=carousel&utm_campaign=88" target="_blank" rel="noopener noreferrer">
                  <img src={process.env.PUBLIC_URL + "images/course4.png"} alt="Description 4" />
              </a>
              <a href="https://www.tailopez.com/flow.php?id=FS-3407&source=carousel&utm_campaign=87" target="_blank" rel="noopener noreferrer">
                  <img src={process.env.PUBLIC_URL + "images/course5.png"} alt="Description 5" />
              </a>
              <a href="https://www.tailopez.com/flow.php?id=FS-6659&source=carousel&utm_campaign=10s" target="_blank" rel="noopener noreferrer">
                  <img src={process.env.PUBLIC_URL + "images/course6.png"} alt="Description 6" />
              </a>
          </div>
      </section>

      {/* Bestselling Courses Section */}
      <section className="container videos-section">
          <h2 className="text-center mb-4 scom-title">
              <span className="scom">TOP FREE</span><br />
              <span className="courses">VIDEO TRAININGS FROM SCOM</span>
          </h2>
          <div className="row">
              {/* Video 1 */}
              <div className="col-md-4 mb-4">
                  <video width="100%" height="315" controls>
                      <source src={process.env.PUBLIC_URL + "images/videos/jeetaliik_2_1.mp4"} type="video/mp4" />
                      Your browser does not support the video tag.
                  </video>
              </div>

              {/* Video 2 */}
              <div className="col-md-4 mb-4">
                  <video width="100%" height="315" controls>
                      <source src={process.env.PUBLIC_URL + "images/videos/Anubis_Scom_1.mp4"} type="video/mp4" />
                      Your browser does not support the video tag.
                  </video>
              </div>

              {/* Video 3 */}
              <div className="col-md-4 mb-4">
                  <video width="100%" height="315" controls>
                      <source src={process.env.PUBLIC_URL + "images/videos/Anubis_Scom_5.mp4"} type="video/mp4" />
                      Your browser does not support the video tag.
                  </video>
              </div>

              {/* Video 4 */}
              <div className="col-md-4 mb-4">
                  <video width="100%" height="315" controls>
                      <source src={process.env.PUBLIC_URL + "images/videos/BananaGun_2.mp4"} type="video/mp4" />
                      Your browser does not support the video tag.
                  </video>
              </div>

              {/* Video 5 */}
              <div className="col-md-4 mb-4">
                  <video width="100%" height="315" controls>
                      <source src={process.env.PUBLIC_URL + "images/videos/Bitboy_SCOM-.mp4"} type="video/mp4" />
                      Your browser does not support the video tag.
                  </video>
              </div>
              {/* Video 6 */}
              <div className="col-md-4 mb-4">
                  <video width="100%" height="315" controls>
                      <source src={process.env.PUBLIC_URL + "images/videos/Bald_Scom.mp4"} type="video/mp4" />
                      Your browser does not support the video tag.
                  </video>
              </div>
          </div>
      </section>

      <section className="join-us-section" id="join-us-section">
          <h2 className="text-center mb-4 scom-title">
              <span className="scom">JOIN US</span><br />
              <span className="courses">BUY AND NEVER SELL SCOM</span>
          </h2>
        <div className="join-content">
            <div id="dexscreener-embed"><iframe src="https://dexscreener.com/ethereum/0xefF3A8A36b6DEB0d2E8AA193728e0196ed8e3341?embed=1&theme=dark"></iframe></div>
            <div className="buy-button-container text-center">
              <button className="buy-scom-button" onClick={handleShow}>BUY SCOM</button>
            </div>
        </div>
      </section>

      {/* Multiple Modals */}
      {Array.from({ length: numOfModalsToShow }).map((_, index) => {
        const position = getRandomPosition();
        return (
        <div className="win98-modal">
          <Modal
            key={index}
            show={true}
            onHide={() => setNumOfModalsToShow(prev => prev - 1)}
            centered
            className="windows98-modal"
            style={{ position: 'fixed', top: `${position.top}px`, left: `${position.left}px` }}
          >
              <div className="modal-header">
                <div className="title-bar-text">Windows</div>
                <div className="title-bar-controls">
                  <button aria-label="Close" onClick={() => setNumOfModalsToShow(prev => prev - 1)} className="close-button">X</button>
                </div>
              </div>
              <div className="window-body">
                <img src="/images/warningtriangle.png" alt="Warning" className="warning-icon" />
                {popupMessages[index]}
              </div>
              <div className="ok-button-container">
                <button className="ok-button" onClick={() => setNumOfModalsToShow(prev => prev - 1)}>OK</button>
              </div>
          </Modal>
        </div>
        );
      })}


      <section className="important-section">
        <h2 className="text-center mb-4 scom-title"><span className="top-free">IMPORTANT</span><br /><span className="trainings">PLEASE READ</span></h2>
        <div className="important-text">
            <p ref={sectionRefs[0]}>
                          Scom World is a Minecraft server created with firm principles developed over more than a
                decade of playing the vanilla game, various modpack versions of the game, and through the
                experience of nearly every possible playstyle in both single and multiplayer
                <br></br>
            </p>
            <p ref={sectionRefs[1]}>
                <br></br>
                1. The developers
                of this Minecraft server saw it as an opportunity to realize everything about the game we felt
                needed improvement or innovation, to achieve the ultimate version of Minecraft. It is safe to
                say the server is highly opinionated, though we’d prefer to see it as a Manifesto, and this white
                paper serves as its pre­chronicle and user’s manual.
                As with all creations, there is a steady compromise between vision and reality. The concepts
                discussed henceforth will not all be fully realized by the time of writing. Many may be realized
                in the future, some may never fully be integrated into the server. Differing opinions exist on
                how to make a perfect Minecraft server, you are simply reading ours 
                <br></br>
                <br></br>
            </p>
            <p ref={sectionRefs[2]}>
                2.
                Ever since the modern conception of immersive experiences detached from physical reality
                within an interactive mode of operation, the pre­eminent hopes and desires of humanity have
                always directed towards the total fulfilment of said immersion: an experience not only functionally indistinguishable from reality, but far more vivid and eclectic; an experience which
                in the creative and technological sphere of the 80s and 90s would be addressed as ‘virtual
                reality,’ ‘cyberspace’ and later on – taking after Neal Stephenson’s novel Snow Crash – ‘the
                metaverse,’ the latter of which is also seeing popularized usage within the sphere of crypto
                today.
                The advent of video games themselves stems from a playful willingness to display one’s
                coding skill for creative output which pushes & showcases the boundary of the machine it
                is produced on, with precedence. Before the gross commodification of the internet – before
                even the internet itself – the computer was the playground of the perspicacious hacker, the
                coder­otaku and anyone who could appreciate their efforts. Take for example, the so­called
            </p>
            <p ref={sectionRefs[3]}>
                ‘demoscene’ which flourished in the before­times, which expressed amicable competition to
                display one’s creative and technical output in the form of shared files and programs called
                demos. This scene developed as a social circle of locales, both within online spheres and as
                real­life meetups, and had no clear wall between friendships of the real and the online. An
                example of a contemporaneous demo can be seen in Fig. 1
                Looking further downstream, video games have always had a high degree of sociality when
                they were able to make players interact with each other in real­time, which could initially, in an
                online environment, be found within both text­based and graphical roleplaying games through
                MUDs 
                <br></br>
                <br></br>
            </p>
            <p ref={sectionRefs[4]}>
                3. engendering imaginative thought akin to the conceits and systems of Dungeons &
                Dragons; and ever since the release of Doom, a direct online socialization experience in the
                form of highly competitive play, immersion within a 3D world that draws in every participant
                and transmogrifies them into their virtual persona, in this case a playername associated with
                a perception that ranges from Nemesis to Noob.
                <br></br>
                <br></br>
                The excitement for gamelike virtual worlds continued into the early MMORPGs 4, such as
                Ultima Online, Everquest and Phantasy Star Online, which were popular, seminal titles at the
                cusp of the 21st century that combined individual focus and progression with an encompassing community effort within an immersive, persistent fantasy world, and the inherent layer of
                socialization therein.
                This social foundation proved to be so influential that it soon developed into spheres that
                were less games than they were materialistic appropriation, stylistic assessment and socialite
                cloutgathering within a cohesive virtual reality, a social facsimile and simulation, a true stretch
                of what the term Massively Multiplayer Online could actually entail; notable examples of which
                are Second Life, IMVU, Habbo Hotel 5 and especially as precursor to wide­sense brand appropriation and advertisement, Playstation Home, depicted in Fig. 2.
                In recent times, consumer availability of capable – for current graphical standards – virtual
                reality headsets and controllers have lurched virtual worlds towards a breakthrough in immersion due to the heightened mode of audiovisual and proprioceptive engagement they offer.
                Many games have thus included VR­supported modes, and VR social hangout spheres such as
                VRChat enjoy a great deal of popularity.
                <br></br>
                <br></br>
            </p>
            <p ref={sectionRefs[5]}>
                    These days, many crypto projects try to integrate their vision with elements of gamification
                and possibly initiation of virtual worlds therein, be it to capitalize on coincidentally formed
                community narrative, or deliberated expansion for the purpose of grasping the luster that these
                elements seem to hold.
                <br></br>
                However, social virtual worlds and their development are perpetually limited by rate of engagement or arousal and the current technological paradigm: not only must the content offered by
                the virtual world be enthralling to a degree that allows the developers to carve out a niche in
                the Net, garnering a sustainable userbase, this userbase then too is indicative of the future of
                the virtual world as social platform through the culture that forms inside and surrounding it,
                wrought from the userbase’s outwardly portrayed interests and sensibilities.
                Failure to establish such an userbase spells imminent death for the virtual world, which
                is why often gamelike elements are introduced to provide users with a solitary experience
                that is obviously enhanced when engaging with others, see also Fig. 3, charting user sentiment
                towards social drives in EverQuest 6 and Fig. 4, plotting guild size over the playerbase of World
                of Warcraft as a gauge for ingroup communities 7
                .
                These worlds often play into users’ sense of materialistic style, by allowing them to customize their virtual persona to great lengths; this can also be seen in the creation of personal
                spaces with custom landscaping, architectural and interior design; both of these elements can
                be compounded with free or curated community additions, enclosed within the virtual world’s
                overarching structure of monetization for upkeep and development, which enables currency
                substantiated value of ownership as an important side effect that must not be overlooked.
            </p>
          </div>
      </section>

      {/* Modal for Swap Widget */}
      <Modal show={showModal} onHide={handleClose} centered dialogClassName="modal-98">
        <Draggable handle=".title-bar">
          <div className="window">
            <div className="title-bar">
              <div className="title-bar-text">Buy SCOM</div>
              <div className="title-bar-controls">
                  <button aria-label="Close" onClick={handleClose} className="close-button">X</button>
              </div>
            </div>
            <div className="window-body">
              <div className="uniswap-widget-container">
                <SwapWidget 
                  theme={darkTheme}
                  width={400}
                  defaultOutputTokenAddress={'0xf8212179CE95b8237Ae8BDc7280288Bb349F78e5'}
                />
              </div>
            </div>
          </div>
        </Draggable>
      </Modal>


      {/* Sticky Banner */}
      <div className="banner-bg">
        <div className="banner-container">
            <div className="banner-text">{messages[currentIndex]}</div>
        </div>
      </div>

      <Footer />

    </div>
  );
}

export default App;
